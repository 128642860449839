import React, {useCallback, useState} from "react";
import styled from "styled-components"
import Title from "../../../../../components/title";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Message} from "../../../../../theme/styledComponents";
import axios from "axios";
import {API_URL} from "../../../../../theme/constans";
import {getCookie} from "../../../../../theme/cookies";

const DeleteUserFromAppUser = () => {
  const [state, setState] = useState();
  const [response, setResponse] = useState();

  const onSubmit = useCallback(() => {
    axios.delete(`${API_URL}/admin/app-users/${state}/draft`, {
      headers: {
        'Authorization': getCookie('token')
      }
    })
      .then(res => {
        setResponse("success")
      })
      .catch(err => {
        console.log(err)
        setResponse("error")
      })
  }, [state])

  return (
    <Wrapper>
      <Title secondary margin="0 40px 10px 0">Usuń dostęp do aplikacji</Title>
      {
        response === "success" &&
        <Message color="green">
          <FontAwesomeIcon icon={faExclamationTriangle} color="green"/> Konto zostało pomyślnie edytowane
        </Message>
      }
      {
        response === "error" &&
        <Message>
          <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało edytować konta
        </Message>
      }
      <Input label="Id użytkownika" value={state} onChange={(e) => setState(e.target.value)}/>
      <Button big onClick={onSubmit} disabled={state === null || state === ""}>Edytuj konto</Button>
    </Wrapper>
  )
}

export default DeleteUserFromAppUser

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 5px;
  background: white;
`;