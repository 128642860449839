import Axios from "axios";
import { useState } from "react";
import { API_URL } from "../../../../../theme/constans";
import { getCookie } from "../../../../../theme/cookies";
import {connect} from "react-redux";
import { Message} from "../../../../../theme/styledComponents";
import {P} from "../../../../../components/p";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../../components/spinner";
import Button from "../../../../../components/button";
import { CompanyState } from "../enums/companyState";

function SetCompanyActiveState (props) {
    const {company, setShow, refresh, stateToSet } = props;
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const onSubmit = () => {
        setLoading(true);
        Axios.patch(getRequestUrl(), null, {
            headers: {
                'Authorization': getCookie('token')
            }
        })
        .then(() => {
            refresh();
            setLoading(false);
            setShow({company: {}, show: false, stateToSet: ''});
        })
        .catch(err => {
            console.log(err);
            setError(true);
            setLoading(false);
            setShow({company: {}, show: false, stateToSet: ''});
        })
    };

    const getRequestUrl = () => {
        return stateToSet === CompanyState.archived ? 
            `${API_URL}/admin/companies/${company.Id}/archive` :
            `${API_URL}/admin/companies/${company.Id}/activate`;
    }

    const getErrorMessage = () => {
        return stateToSet === CompanyState.archived ? 
            'Nie udało się zarchiwizować firmy' : 'Nie udało się aktywować firmy';
    }

    const getConfirmationMessage = () => {
        return stateToSet === CompanyState.archived ? 
            'Czy na pewno chcesz zarchiwizować firmę' : 'Czy na pewno chcesz aktywować firmę'
    }

    return (
        <>
            {
                error &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {getErrorMessage()}
                </Message>
            }
            <P>{getConfirmationMessage()} <span>{company?.Name}</span>?</P>
            <div>
                <Button big style={{marginTop:'30px'}} onClick={onSubmit}>
                    {
                        loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Tak'
                    }
                </Button>
            </div>
        </>
    )

}

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(SetCompanyActiveState);