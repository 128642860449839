import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./redux/reducers";

export default function configureStore(persistedState) {


    const store = createStore(rootReducer,persistedState,  compose(applyMiddleware(thunkMiddleware)));

    // store.dispatch(verifyAuth());
    return store;
}