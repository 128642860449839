import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {Flex, ModalBackground, ModalContent, Message} from "../../../../../theme/styledComponents";
import Title from "../../../../../components/title";
import Input from "../../../../../components/input";
import SubTitle from "../../../../../components/subtitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../components/button";
import axios from "axios";
import {API_URL} from "../../../../../theme/constans";
import {connect} from "react-redux";
import {getCookie} from "../../../../../theme/cookies";

function AddCompany (props) {
    const {refresh,setRefresh,goBack} = props;

    const [valid,setValid] = useState(true);
    const [loading,setLoading] = useState();
    const [error,setError] = useState();

    const [state,setState] = useState({
        name:'',
        username:'',
        password:'',
        cardTypes:[''],
        currencies:[''],
        expensesTypes:[''],
        fuelTypes:['']
    });

    const onSubmit = () => {
        setLoading(true);
        axios.post(`${API_URL}/admin/companies`,{
            Name: state.name,
            Username: state.username,
            Password: state.password,
            OnlyOneActivePlace: true,
            CardTypes: state.cardTypes,
            Currencies: state.currencies,
            ExpensesTypes: state.expensesTypes,
            fuelTypes: state.fuelTypes,
            Archived: false
        },{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setLoading(false);
                setRefresh(!refresh);
                goBack();
            })
            .catch(err => {
                setError(true);
                setLoading(false);
            })
    };

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    };

    const onChangeArrayInput = (e,index) => {
        const element = e.target.value;
        const array = state[e.target.name];
        array[index] = element;

        setState({
            ...state,
            [e.target.name]:array
        })
    };

    const validState = () => {
        const element = state.name === '' || state.username === '' || state.password === '' || state.cardTypes.length === 0 || state.currencies.length === 0 || state.expensesTypes.length === 0;
        const validCardTypes = state.cardTypes.filter(item => item === '').length > 0;
        const validCurrencies = state.currencies.filter(item => item === '').length > 0;
        const validExpanse = state.expensesTypes.filter(item => item === '').length > 0;
        const fuelTypes = state.fuelTypes.filter(item => item === '').length > 0;

        setValid(element || validCardTypes || validCurrencies || validExpanse || fuelTypes);
    };

    useEffect(() => {
        validState();
    },[state]);

    const addNewRow = (name) => {
        const array = state[name];
        array.push('');

        setState({
            ...state,
            [name]:array
        })
    };
    return(
        <>
            <ModalBackground className={'show'} onClick={goBack}>
                <ModalContent maxWidth="450px" onClick={(e) => e.stopPropagation()}>
                    <Flex jc="space-between">
                        <Title secondary>{'Dodawanie firmy'}</Title>
                        <div onClick={goBack}>
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </div>
                    </Flex>
                    <Content>
                        <Title third margin="15px 0 10px">Dane firmy</Title>
                        <Input label="Nazwa*" name="name" value={state.name} onChange={onChange}/>
                        <Input label="Nazwa użytkownika*" name="username" value={state.username} onChange={onChange}/>
                        <Input password type="password" label="Hasło*" name="password" value={state.password} onChange={onChange}/>
                        <Title third margin="15px 0 10px">Konfiguracja</Title>
                        <SubTitle small>Wydatki</SubTitle>
                        {
                            state.expensesTypes.map((item,index) =>
                                <Input name="expensesTypes" value={state.expensesTypes[index]} onChange={(e) => onChangeArrayInput(e,index)}/>
                            )
                        }
                        {
                            state.expensesTypes[state.expensesTypes.length - 1].length > 0 &&
                            <AddNewInput onClick={() => addNewRow('expensesTypes')}>Dodaj <FontAwesomeIcon icon={faPlus}/></AddNewInput>
                        }
                        <SubTitle small>Typy kart</SubTitle>
                        {
                            state.cardTypes.map((item,index) =>
                                <Input name="cardTypes" value={state.cardTypes[index]} onChange={(e) => onChangeArrayInput(e,index)}/>
                            )
                        }
                        {
                            state.cardTypes[state.cardTypes.length - 1].length > 0 &&
                                <AddNewInput onClick={() => addNewRow('cardTypes')}>Dodaj <FontAwesomeIcon icon={faPlus}/></AddNewInput>
                        }
                        <SubTitle small>Waluty</SubTitle>
                        {
                            state.currencies.map((item,index) =>
                                <Input name="currencies" value={state.currencies[index]} onChange={(e) => onChangeArrayInput(e,index)}/>
                            )
                        }
                        {
                            state.currencies[state.currencies.length - 1].length > 0 &&
                            <AddNewInput onClick={() => addNewRow('currencies')}>Dodaj <FontAwesomeIcon icon={faPlus}/></AddNewInput>
                        }
                        <SubTitle small>Rodzaje paliwa</SubTitle>
                        {
                            state.fuelTypes.map((item,index) =>
                              <Input name="fuelTypes" value={state.fuelTypes[index]} onChange={(e) => onChangeArrayInput(e,index)}/>
                            )
                        }
                        {
                          state.fuelTypes[state.fuelTypes.length - 1].length > 0 &&
                          <AddNewInput onClick={() => addNewRow('fuelTypes')}>Dodaj <FontAwesomeIcon icon={faPlus}/></AddNewInput>
                        }
                        {
                            error &&
                            <Message>
                                Nie udało się dodać użytkownika. Spróbuj podać inną nazwę użytkownika.
                            </Message>

                        }
                        <Button big style={{marginTop:'30px'}} onClick={onSubmit} disabled={valid}>
                            {
                                loading ? 'Loading ...' : 'Dodaj firmę'
                            }
                        </Button>
                    </Content>
                </ModalContent>
            </ModalBackground>
        </>
    )
};

AddCompany.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(AddCompany);

const AddNewInput = styled.h2`
  cursor: pointer;
  text-align: center;
  color: ${({theme}) => theme.primaryColor};
  font-size: ${({theme}) => theme.font.XS};
  svg{
    margin-left: 10px;
  }
`;

const DownloadInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({theme}) => theme.thirdColor};
  transition: all 0.3s;
  cursor: pointer;
  
  &:hover{
     color: ${({theme}) => theme.primaryColor};
  }
`;

const Content = styled.div`
  
`;
