import React, { useState } from 'react';
import styled from 'styled-components'
import Title from "../../../../components/title";
import Button from "../../../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExternalLinkAlt,
  faLock,
  faPen,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import Search from "../../../../components/search";
import Spinner from "../../../../components/spinner";
import Table, { Row } from "../../../../components/table";
import Modal from "../../../../components/modal";
import AddUser from "./modals/addUser";
import ChangePassword from "./modals/changePassword";
import UpdateUser from "./modals/updateUser";
import { theme } from "../../../../theme/theme";
import ActivateVehicle from "../vehicles/modals/activateVehicle";
import ActivateDriver from "./modals/activateDriver";
import DeleteDriver from "./modals/deleteDriver";

function Drivers(props) {
  const {user} = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  return (
    <>
      <Wrapper>
        <ContentHeader>
          <Title>Wszyscy kierowcy</Title>
          {
            loading &&
            <div style={{marginLeft: 10}}>
              <Spinner width={20} height={20}/>
            </div>
          }
        </ContentHeader>
        <ContentBody>
          <FiltersWrapper>
            <div>
              <Search value={search} onChange={(e) => setSearch(e.target.value)} name="search" autoComplete="off"
                      label="Imię / Nazwisko / Nazwa użytkownika"/>
            </div>
            <div>
              <Button onClick={() => setShowAddModal(true)}><FontAwesomeIcon icon={faPlusCircle}/>Dodaj
                kierowcę</Button>
            </div>
          </FiltersWrapper>
          <Table
            url={`/companies/${user.CompanyId}/drivers`}
            q={search}
            columns={['Nr. kierowcy', 'Nazwa użytkownika', 'Imię', 'Nazwisko', null]}
            rows={(driver) =>
              <Row data={[
                `#${driver.PersonId}`,
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{
                    color: driver['Status'] === 'ACTIVE' ? theme.green : theme.red,
                    margin: '0 10px 0 0'
                  }}/>
                  {driver.IsExternal && <FontAwesomeIcon icon={faExternalLinkAlt} style={{margin: '0 10px 0 0'}}/>}
                  {driver.Username}
                </div>,
                driver.Forename,
                driver.Surname,
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {
                    driver['Status'] === 'ACTIVE' ?
                      <>
                        <FontAwesomeIcon style={{margin: '0 10px', cursor: 'pointer'}} icon={faLock}
                                         onClick={() => {
                                           setShowChangePassModal(driver);
                                         }}/>
                        <FontAwesomeIcon style={{margin: '0 10px', cursor: 'pointer'}} icon={faPen}
                                         onClick={() => {
                                           setShowEditModal(driver);
                                         }}/>
                        <FontAwesomeIcon style={{margin: '0 10px', cursor: 'pointer'}} icon={faTrash}
                                         onClick={() => {
                                           setShowDeleteModal(driver);
                                         }}/>
                      </> :
                      <>
                        <Button
                          fourth
                          onClick={() => {
                            setShowDraftModal(driver);
                          }}>
                          Aktywuj
                        </Button>
                        <FontAwesomeIcon style={{margin: '0 10px', cursor: 'pointer'}} icon={faTrash}
                                         onClick={() => {
                                           setShowDeleteModal(driver);
                        }}/>
                      </>
                  }
                </div>
              ]}/>
            }
            setLoading={setLoading}
            refresh={refresh}
          />
        </ContentBody>
      </Wrapper>

      <Modal show={showDraftModal !== false} setShow={setShowDraftModal} title="Aktywuj aplikację" maxWidth="400">
        <ActivateDriver driver={showDraftModal} setShow={setShowDraftModal} refresh={() => setRefresh(!refresh)}/>
      </Modal>

      <Modal show={showAddModal} setShow={setShowAddModal} title="Dodawanie kierowcy" maxWidth="400">
        <AddUser refresh={() => setRefresh(!refresh)} setShow={setShowAddModal}/>
      </Modal>

      <Modal show={showEditModal} setShow={setShowEditModal} title="Aktualizuj informacje" maxWidth="400">
        <UpdateUser refresh={() => setRefresh(!refresh)} driver={showEditModal} setShow={setShowEditModal}/>
      </Modal>

      <Modal show={showChangePassModal !== false} setShow={setShowChangePassModal} title="Zmiana hasła" maxWidth="400">
        <ChangePassword driver={showChangePassModal} setShow={setShowChangePassModal}/>
      </Modal>

      <Modal show={showDeleteModal !== false} setShow={setShowDeleteModal} title="Usuń dostęp kierowcy" maxWidth="400">
        <DeleteDriver refresh={() => setRefresh(!refresh)} driver={showDeleteModal} setShow={setShowDeleteModal}/>
      </Modal>
    </>
  )
};

Drivers.propTypes = {};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Drivers);

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-bottom: 10px;
  }

  > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > div {
      margin-right: 10px;
    }
  }

`;

const ContentBody = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background: white;
`;

const Wrapper = styled.div`

`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
