import React, {useEffect, useState} from 'react';
import {Message} from "../../../../../theme/styledComponents";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import axios from "axios";
import {API_URL} from "../../../../../theme/constans";
import {connect} from "react-redux";
import {getCookie} from "../../../../../theme/cookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../../components/spinner";
import Checkbox from "../../../../../components/checkbox";

function UpdateVehicle (props) {
    const {refresh,vehicle,setShow,user} = props;

    const [valid,setValid] = useState(true);
    const [loading,setLoading] = useState();
    const [error,setError] = useState(null);

    const [state,setState] = useState({
        LicensePlate: vehicle.LicensePlate,
        IsExternal: vehicle.IsExternal,
    });

    const onSubmit = () => {
        setLoading(true);
        axios.patch(`${API_URL}/companies/${user.CompanyId}/vehicles/${vehicle.VehicleId}?licensePlate=${state.LicensePlate}&isExternal=${state.IsExternal}`,null,{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setLoading(false);
                refresh();
                setShow(false);
            })
            .catch(err => {
                setError(err.response.data.errors);
                setLoading(false);
            })
    };

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    };

    useEffect(() => {
        const element = state.LicensePlate === ''

        setValid(element);
    },[state]);

    return(
        <>
            {
                error !== null &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <div>
                        Nie udało się zaktualizować pojazdu.
                        <span>Error: {JSON.stringify(error.join(", "))}</span>
                    </div>
                </Message>

            }
            <Input label="Numer rejestracyjny*" name="LicensePlate" value={state.LicensePlate} onChange={onChange}/>
            {
                vehicle.Active &&
                <Checkbox value={state.IsExternal} onChange={(e) => setState({...state, IsExternal: e.target.checked})}
                          label="Pojazd spedycyjny"/>
            }
            <Button big style={{marginTop:'10px'}} onClick={onSubmit} disabled={valid}>
                {
                    loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Zapisz'
                }
            </Button>
        </>
    )
};

UpdateVehicle.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(UpdateVehicle);
