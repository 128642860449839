import { faCheckCircle, faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import Button from "../../../../components/button";
import Title from "../../../../components/title";

import axios from 'axios';
import { connect } from "react-redux";
import Input from "../../../../components/input";
import Spinner from "../../../../components/spinner";
import Switch from "../../../../components/switch";
import { API_URL } from "../../../../theme/constans";
import { getCookie } from "../../../../theme/cookies";
import { AlertError, AlertSuccess, Flex } from "../../../../theme/styledComponents";
import AdditionalInfo from './additionalInfo';
import { AddTypeBtn, Block, ButtonWrapper, InputWrapper, TagsWrapper, Wrapper } from './configurationElements';
import { Snackbar } from "@material-ui/core";

function Configuration ({user}) {
    const [loading,setLoading] = useState(false);
    const [sending,setSending] = useState(false);
    const [alertProps,setAlertProps] = useState({visible: false, text: '', success: true});
    const [additionalInfoDictionary,setAdditionalInfoDictionary] = useState({});

    const [state,setState] = useState({
        CardTypes:[],
        Currencies:[],
        ExpensesTypes:[],
        PlaceStatuses:[],
        FuelTypes:[],
        CompanyPlaceFields:[],
        OnlyOneActivePlace:true,
        WeightFieldRequired:false,
        ChangePlaceDate:false,
        PlaceActiveDays:0,
        PlaceHistoryDays:0,
        OdometerInputEnabled:false,
        FuelInPercent:false,
        IsBarcodeScannerActive:false
    });

    const getConf = () => {    
        return axios.get(`${API_URL}/companies/${user.CompanyId}/configuration`,{
            headers:{
                'Authorization': getCookie('token')
            }
        });
    };

    const getAdditionalInfoDictionary = () => {
        return axios.get(`${API_URL}/dictionary/placeAdditionalInfo`, {
            headers: {
                'Authorization': getCookie('token')
            }
        });
    }
    
    const getData = () => {
        setLoading(true);
        Promise.all([getConf(), getAdditionalInfoDictionary()])
            .then(([config, dictionary]) => {
                setState(config.data);
                setAdditionalInfoDictionary(dictionary.data);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getData();
    },[]);

    const deleteOption = (name,option) => {
        const array = state[name].filter(el => el !== option);

        setState({
            ...state,
            [name]:array
        })
    };

    const addOption = (name) => {
        const array = state[name];
        array.push('');

        setState({
            ...state,
            [name]:array
        })
    };

    const addPlaceFieldOption = () => {
        if (canAddOption() === false) return;
        setState({
            ...state,
            CompanyPlaceFields: [...state.CompanyPlaceFields, {
                Name: '',
                Code: '',
                Type: "STRING"
            }]
        })
    };

    const canAddOption = () => {
        if (state.CompanyPlaceFields.length === additionalInfoDictionary.length) return false;
        if (state.CompanyPlaceFields.findIndex(p => p.Name === '') !== -1) return false;

        return true;
    }

    const saveConf = () => {
        setSending(true);
        let obj = {
            ...state,
            CompanyPlaceFields: state.CompanyPlaceFields.filter(el => el.Code !== '')
        }

        if (obj.CardTypes.length === 0 ||
            obj.FuelTypes.length === 0 ||
            obj.Currencies.length === 0 ||
            obj.ExpensesTypes.length === 0
        ) {
            setAlertProps({visible: true, text: 'Uzupełnij wymagane pola', success: false});
            setSending(false);
            return;
        }

        axios.patch(`${API_URL}/companies/${user.CompanyId}/configuration`,obj,
            {
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setSending(false);
                setState(res.data);
                setAlertProps({visible: true, text: 'Zapisano', success: true});
            }).catch((err) => {
                setAlertProps({visible: true, text: `Błąd serwera. Spróbuj ponownie później`, success: false});
                setSending(false);
                console.log(err)
        });
    };

    const onChangeInput = (e,index,name) => {
        const array = state[name];
        array[index] = e.target.value;

        setState({
            ...state,
            [name]:array
        })
    };

    const onChangeStatusSwitch = (value) => {
        let array = state.PlaceStatuses;

        if(array.includes(value)){
            array = array.filter(el => el !== value)
        }else {
            array = [...array, value]
        }

        setState({
            ...state,
            PlaceStatuses: array
        })
    };

    const onChangeSwitch = (name) => {
        setState({
            ...state,
            [name]: !state[name]
        })
    };

    const onChange = (e) => {
        const {name, value} = e.target
        setState({
            ...state,
            [name]: value
        })
    };

    const handleOnAlertClose = (e) => {
        setAlertProps({visible: false, text: ''});
    }

    return(
        <>  
            <Wrapper>
                {
                    loading ?
                        <Flex jc="center">
                            <Spinner width={20} height={20}/>
                        </Flex> :
                        <>
                            <Block>
                                <Title third margin="0 0 10px">Ogólne ustawienia</Title>
                                <Switch checked={state.OnlyOneActivePlace} onChange={() => onChangeSwitch('OnlyOneActivePlace')} label="Wykonywanie tylko pierwszego zadania" />
                                <Switch checked={state.WeightFieldRequired} onChange={() => onChangeSwitch('WeightFieldRequired')} label="Wymagane pole wagi załadunku/rozładunku" />
                                <Switch checked={state.ChangePlaceDate} onChange={() => onChangeSwitch('ChangePlaceDate')} label="Pozwalaj zmieniać datę w zakończeniu zadania" />
                                <Switch checked={state.FuelInPercent} onChange={() => onChangeSwitch('FuelInPercent')} label="Paliwo w karcie drogowej podawane w procentach baku" />
                                <Switch checked={state.OdometerInputEnabled} onChange={() => onChangeSwitch('OdometerInputEnabled')} label="Wymagane podanie wartości licznika w karcie drogowej" />
                                <Switch checked={state.IsBarcodeScannerActive} onChange={() => onChangeSwitch('IsBarcodeScannerActive')} label="Zeskanowanie kodu umożliwia kończenie zadań" />
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Typy wydatków*</Title>
                                <TagsWrapper>
                                    {
                                        state.ExpensesTypes.map((expenseType,index) =>
                                            <InputWrapper>
                                                <FontAwesomeIcon icon={faTimesCircle}
                                                    onClick={() => deleteOption('ExpensesTypes', expenseType)}/>
                                                    <Input value={state.ExpensesTypes[index]} onChange={(e) => onChangeInput(e,index,'ExpensesTypes')}/>
                                            </InputWrapper>
                                        )
                                    }
                                    <AddTypeBtn onClick={() => addOption('ExpensesTypes')}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddTypeBtn>
                                </TagsWrapper>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Typy kart*</Title>
                                <TagsWrapper>
                                    {
                                        state.CardTypes.map((cardType,index) =>
                                            <InputWrapper>
                                                <FontAwesomeIcon icon={faTimesCircle}
                                                                 onClick={() => deleteOption('CardTypes', cardType)}/>
                                                <Input value={state.CardTypes[index]} onChange={(e) => onChangeInput(e,index,'CardTypes')}/>
                                            </InputWrapper>
                                        )
                                    }
                                    <AddTypeBtn onClick={() => addOption('CardTypes')}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddTypeBtn>
                                </TagsWrapper>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Waluty*</Title>
                                <TagsWrapper>
                                    {
                                        state.Currencies.map((currencie,index) =>
                                            <InputWrapper>
                                                <FontAwesomeIcon icon={faTimesCircle}
                                                                 onClick={() => deleteOption('Currencies', currencie)}/>
                                                <Input value={state.Currencies[index]} onChange={(e) => onChangeInput(e,index,'Currencies')}/>
                                            </InputWrapper>
                                        )
                                    }
                                    <AddTypeBtn onClick={() => addOption('Currencies')}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddTypeBtn>
                                </TagsWrapper>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Paliwa*</Title>
                                <TagsWrapper>
                                    {
                                        state.FuelTypes.map((fuelType,index) =>
                                          <InputWrapper>
                                              <FontAwesomeIcon icon={faTimesCircle}
                                                               onClick={() => deleteOption('FuelTypes', fuelType)}/>
                                              <Input value={state.FuelTypes[index]} onChange={(e) => onChangeInput(e,index,'FuelTypes')}/>
                                          </InputWrapper>
                                        )
                                    }
                                    <AddTypeBtn onClick={() => addOption('FuelTypes')}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddTypeBtn>
                                </TagsWrapper>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Liczba dni aktywności punktu</Title>
                                <Input name="PlaceActiveDays" value={state.PlaceActiveDays} onChange={onChange}/>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Liczba dni historii punktu</Title>
                                <Input name="PlaceHistoryDays" value={state.PlaceHistoryDays} onChange={onChange}/>
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Statusy zadań</Title>
                                <Switch value={107} checked={state.PlaceStatuses.includes(107)} onChange={onChangeStatusSwitch} label="Oczekiwanie na zadanie" />
                                <Switch value={103} checked={state.PlaceStatuses.includes(103)} onChange={onChangeStatusSwitch} label="Dotarcie do celu" />
                                <Switch value={14} checked={state.PlaceStatuses.includes(14)} onChange={onChangeStatusSwitch} label="Wykonywanie czynności" />
                            </Block>
                            <Block>
                                <Title third margin="0 0 10px">Dodatkowe pytania do kierowcy</Title>
                                {
                                    state.CompanyPlaceFields.map((el, index) => <AdditionalInfo index={index} state={state} setState={setState} item={el} placeDictionary={additionalInfoDictionary}/>)
                                }
                                <AddTypeBtn disabled={() => true} onClick={() => addPlaceFieldOption()}>
                                    <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                </AddTypeBtn>
                            </Block>
                        </>
                }
            </Wrapper>
            <ButtonWrapper>
                <Snackbar open={alertProps.visible} autoHideDuration={50000} onClose={handleOnAlertClose}>
                    {
                    alertProps.success ?
                    <AlertSuccess>
                        <FontAwesomeIcon icon={faCheckCircle} color="green" style={{marginRight: '10px'}}></FontAwesomeIcon>
                        {alertProps.text}
                    </AlertSuccess>
                    :
                    <AlertError>
                        <FontAwesomeIcon icon={faTimesCircle} color="red" style={{marginRight: '10px'}}></FontAwesomeIcon>
                        {alertProps.text}
                    </AlertError>
                    }
                </Snackbar>
                <Button onClick={saveConf}>
                    {
                        sending ?
                            <Spinner borderWidth={3} white width={10} height={10}/> :
                            'Zapisz'
                    }
                </Button>
            </ButtonWrapper>
        </>
    )
}

Configuration.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user,
    };
}
export default connect(mapStateToProps)(Configuration);
