import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faTimesCircle,} from "@fortawesome/free-solid-svg-icons";

import {connect} from "react-redux";
import axios from 'axios';
import {useHistory} from "react-router-dom";
import {API_URL} from "../../../../theme/constans";
import {getCookie} from "../../../../theme/cookies";
import Spinner from "../../../../components/spinner";
import {Flex} from "../../../../theme/styledComponents";
import Input from "../../../../components/input";
import Search from "../../../../components/search";

function PlateNumbers ({user}) {
    const [loading,setLoading] = useState(false);
    const [sending,setSending] = useState(false);
    const [deleting,setDeleting] = useState(false);
    const [search,setSearch] = useState('');
    const [plateNumber,setPlateNumber] = useState('');

    const [state,setState] = useState([]);

    const getConf = () => {
        setLoading(true);
        axios.get(`${API_URL}/companies/${user.CompanyId}/configuration`,{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setLoading(false);
                setState(res.data.PlateNumbers);
            }).catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        getConf();
    },[]);

    const addPlate = () => {
        setSending(true);
        axios.post(`${API_URL}/companies/${user.CompanyId}/configuration/plates?plateNumber=${plateNumber}`,null,
            {
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setSending(false);
                setState(res.data);
                setPlateNumber('');
            }).catch((err) => {
            console.log(err)
        });
    };

    const deletePlate = (plateNumber) => {
        setDeleting(plateNumber);
        axios.delete(`${API_URL}/companies/${user.CompanyId}/configuration/plates?plateNumber=${plateNumber}`,
            {
                headers:{
                    'Authorization': getCookie('token')
                }
            })
            .then(res => {
                setDeleting(false);
                setState(res.data);
            }).catch((err) => {
            console.log(err)
        });
    };

    return(
        <>
            <Wrapper>
                {
                    loading ?
                        <Flex jc="center">
                            <Spinner width={20} height={20}/>
                        </Flex> :
                        <>
                            {/*<Input label="Wyszukaj" value={search} onChange={(e) => setSearch(e.target.value)}/>*/}
                            <Search margin="0 0 10px" value={search} onChange={(e) => setSearch(e.target.value.toUpperCase().replace(' ',''))} label="Wyszukaj"/>
                            <Block>
                                <TagsWrapper>
                                    {
                                        state.filter(e => e.includes(search)).map((plate,index) =>
                                            <PlateWrapper>
                                                {plate}
                                                {
                                                    deleting === plate ?
                                                        <Spinner borderWidth={3} width={10} height={10}/> :
                                                        <FontAwesomeIcon icon={faTimesCircle}
                                                                         onClick={() => deletePlate(plate)}/>
                                                }
                                            </PlateWrapper>
                                        )
                                    }
                                </TagsWrapper>
                            </Block>
                        </>
                }
            </Wrapper>
            <ButtonWrapper>
                <Input label="Dodaj rejestracje" value={plateNumber} onChange={(e) => setPlateNumber(e.target.value.toUpperCase().replace(' ',''))}/>
                <StyledButton onClick={addPlate}>
                    {
                        sending ?
                            <Spinner borderWidth={3} white width={10} height={10}/> :
                            <FontAwesomeIcon icon={faPaperPlane}/>
                    }
                </StyledButton>
            </ButtonWrapper>
        </>
    )
};

PlateNumbers.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user,
    };
}
export default connect(mapStateToProps)(PlateNumbers);


const StyledButton = styled.button`
  height: 51px;
  width: 51px;
  background: ${({theme}) => theme.primaryColor};
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: white;
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({theme}) => theme.fourthColor};
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
`;

const PlateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.S};
  font-weight: 500;
  padding: 10px 0;
  border-bottom: 1px solid ${({theme}) => theme.fourthColor};
  
  svg{
    margin-left: 10px;
    font-size: ${({theme}) => theme.font.XS};
    color: ${({theme}) => theme.secondColor};
    opacity: 0.5;
    transition: all 0.3s;
    cursor: pointer;
    
    &:hover{
      opacity: 1;
    }
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Block = styled.div`
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  
`;