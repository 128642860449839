import React, {useState} from 'react';
import styled from 'styled-components'
import Title from "../../../../components/title";
import Button from "../../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faCircle, faLock, faPen, faTrash, faTrashRestoreAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import Search from "../../../../components/search";
import AddCompany from "./modals/addCompany";
import Spinner from "../../../../components/spinner";
import Table, {Row} from "../../../../components/table";
import { theme } from "../../../../theme/theme";
import Modal from '../../../../components/modal';
import SetCompanyActiveState from './modals/setCompanyActiveState';
import { CompanyState } from './enums/companyState';
import ChangeCompanyPassword from './modals/changeCompanyPassword';
import EditCompany from './modals/editCompany';

function Companies (props) {
    const [showAddModal,setShowAddModal] = useState(false);

    const [search,setSearch] = useState('');
    const [loading,setLoading] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [editModal, setEditModal] = useState({company: {}, show: false});
    const [changePasswordModal, setChangePasswordModal] = useState({company: {}, show: false});
    const [companyStateModal, setCompanyStateModal] = useState({company: {}, show: false, stateToSet: ''});

    return(
        <>
            <Wrapper>
                <ContentHeader>
                    <Title>Wszystkie firmy</Title>
                    {
                        loading &&
                        <div style={{marginLeft:10}}>
                            <Spinner width={20} height={20}/>
                        </div>
                    }
                </ContentHeader>
                <ContentBody>
                    <FiltersWrapper>
                        <div>
                            <Search value={search} onChange={(e) => setSearch(e.target.value)} autoComplete="off" label="Nazwa firmy"/>
                        </div>
                        <div>
                            <Button onClick={() => setShowAddModal(true)}><FontAwesomeIcon icon={faPlusCircle}/>Dodaj firmę</Button>
                        </div>
                    </FiltersWrapper>
                    <Table
                        url='/admin/companies'
                        columns={['Nr. firmy','Nazwa użytkownika','Nazwa firmy', 'Aktywna', ""]}
                        q={search}
                        rows={(el) =>
                            <Row data={[
                                el.Id,
                                el.Username,
                                el.Name,
                                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                  <FontAwesomeIcon icon={faCircle} style={{
                                    color: el.Archived ? theme.red : theme.green,
                                    margin: '0 0 0 25px'
                                  }}/>
                                </div>,
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {
                                    <>
                                      <FontAwesomeIcon title='Zmień hasło' style={{margin: '0 10px', cursor: 'pointer'}} icon={faLock}
                                                       onClick={() => {
                                                         setChangePasswordModal({company: el, show: true});
                                                       }}/>
                                      <FontAwesomeIcon title='Edytuj' style={{margin: '0 10px', cursor: 'pointer'}} icon={faPen}
                                                       onClick={() => {
                                                          setEditModal({company: el, show: true});
                                                       }}/>
                                      {el.Archived === false ? 
                                        <>
                                          <FontAwesomeIcon title='Zarchiwizuj' style={{margin: '0 10px', cursor: 'pointer'}} icon={faTrash} onClick={() => {
                                            setCompanyStateModal({company: el, show: true, stateToSet: CompanyState.archived});
                                          }}/>
                                        </>
                                        :
                                        <>
                                          <FontAwesomeIcon title='Aktywuj' style={{margin: '0 10px', cursor: 'pointer'}} icon={faTrashRestoreAlt} onClick={() => {
                                              setCompanyStateModal({company: el, show: true, stateToSet: CompanyState.active});
                                            }}/>
                                        </>
                                      }
                                    </>
                                }
                              </div>
                            ]}/>
                        }
                        setLoading={setLoading}
                        refresh={refresh}
                    />
                </ContentBody>
            </Wrapper>
            <Modal show={companyStateModal.show} setShow={setCompanyStateModal} title="Stan firmy" maxWidth="400">
              <SetCompanyActiveState company={companyStateModal.company} stateToSet={companyStateModal.stateToSet} setShow={setCompanyStateModal} refresh={() => setRefresh(!refresh)}/>   
            </Modal>
            <Modal show={changePasswordModal.show} setShow={setChangePasswordModal} title="Zmiana hasła" maxWidth="400">
              <ChangeCompanyPassword company={changePasswordModal.company} setShow={setChangePasswordModal} refresh={() => setRefresh(!refresh)}/>
            </Modal>
            <Modal show={editModal.show} setShow={setEditModal} title="Edytuj firmę" maxWidth="400">
              <EditCompany company={editModal.company} setShow={setEditModal} refresh={() => setRefresh(!refresh)}/>
            </Modal>
            {
                showAddModal &&
                <AddCompany
                    refresh={refresh}
                    setRefresh={setRefresh}
                    goBack={() => setShowAddModal(false)}
                />
            }
        </>
    )
};

Companies.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth,
    };
}

export default connect(mapStateToProps)(Companies);

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  span{
    font-size: ${({theme}) => theme.font.S};
    font-weight: ${({theme}) => theme.font.Regular};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    
    &.arrow-notactive{
      opacity: 0;
    }
    
    &:hover{
      color: ${({theme}) => theme.primaryColor};
    }
    &.active{
      background: ${({theme}) => theme.primaryColor};
      color: white;
    }
  }
`;
const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  >div{
   margin-bottom: 10px;
  }
  >div:first-of-type{
    display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
        >div{
            margin-right: 10px;
         }
  }
  
`;

const ContentBody = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background: white;
`;

const Wrapper = styled.div`
  
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;