import React, { useCallback } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch as MUSwitch, withStyles } from '@material-ui/core';
import { theme } from "../theme/theme";

const StyledSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: theme.primaryColor,
        },
        '&$checked + $track': {
            backgroundColor: theme.primaryColor,
        },
    },
    checked: {},
    track: {},
})(MUSwitch);

const Switch = ({checked, value, onChange, label}) => {
    const handleChange = useCallback(() => {
        onChange(value)
    }, [value, onChange])

    return (
        <div>
            <FormControlLabel
                control={
                    <StyledSwitch
                        checked={checked}
                        onChange={handleChange}
                    />
                }
                label={label}
            />
        </div>
    );
}

export default Switch;

