import styled, {css} from "styled-components";

const Button = styled.button`
  font-size: ${({theme}) => theme.font.S};
  color: white;
  font-weight: ${({theme}) => theme.font.Regular};
  background: ${({theme,background}) => background || theme.primaryColor};
  padding: 8px 25px;
  border-radius: 5px;
  border: none;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  
  svg{
    margin-right: 10px;
    font-size: ${({theme}) => theme.font.XS};
  }
  
   ${({secondary}) => secondary && css`
    background: ${({theme}) => theme.fourthColor};
    color: ${({theme}) => theme.secondColor};
    &:hover{
       color: ${({theme}) => theme.primaryColor};
    }
  `}
   
   ${({third}) => third && css`
    background: none;
    color: ${({theme}) => theme.secondColor};
     padding: 0px 25px;
     &:focus{
      outline: none;
     }
    &:hover{
       color: ${({theme}) => theme.primaryColor};
    }
  `}

    ${({fourth}) => fourth && css`
      background: ${({theme}) => theme.primaryColor};
      color: white;
      padding: 5px 10px;
      margin: 0px;
      font-size: 12px;
      min-height: auto;
      margin-top: -5px;
      margin-bottom: -5px;
      border-radius: 3px;
    `}
   
${({big}) => big && css`
 width: 100%;
 line-height: ${({theme}) => theme.font.XL};
`}

${({disabled}) => disabled && css`
 filter: grayscale(100%);
 opacity: 0.3;
 cursor: not-allowed;
`}
`;

export default Button;
