import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import Select from "../../../../components/select";
import { Grid } from "../../../../theme/styledComponents";
import { InputWrapper } from './configurationElements';

function AdditionalInfo ({ index, item, state, setState, placeDictionary}) {
    const [options,setOptions] = useState([]);

    const getSelectOptions = () => {
        const selectableOptions = placeDictionary.filter(p => notSelectedInConfiguration(p.Id));

        const currentOption = placeDictionary.find(p => p.Id === item.Id);
        if (currentOption) selectableOptions.push(currentOption);

        return selectableOptions.map(({Name, Id}) => ({key: Id, value: Name}));
    }

    const notSelectedInConfiguration = (id) => {
        return state.CompanyPlaceFields.findIndex(p => p.Id === id) === -1;
    }

    useEffect(() => {
        setOptions(getSelectOptions());
    }, [item]);

    const deleteOption = useCallback(() => {
        setState({
            ...state,
            CompanyPlaceFields: state.CompanyPlaceFields.filter(el => !(el.Name === item.Name && el.Code === item.Code && el.Type === item.Type)),
        })
    }, [item, state])

    const handleChangeSelect = useCallback((e) => { 
        let id = e.target.value;
        const selected = placeDictionary.find((place) => place.Id === id);
        setState(update(state, {
            CompanyPlaceFields: {[index]: {$set: selected}},
        }))
    }, [item, state])

    return (
        <InputWrapper key={index}>
            <FontAwesomeIcon icon={faTimesCircle} onClick={deleteOption}/>
            <Grid gtc="auto" margin="0 0 10px">
                <Select value={item.Id} renderValue={() => item.Name} options={options} label="Nazwa" onChange={handleChangeSelect}/>
            </Grid>
        </InputWrapper>
    )
}

export default AdditionalInfo;