import React from 'react';
import styled from 'styled-components';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import ProtectedRoute from "../components/protectedRoute";
import Login from "./login/login";
import {default as AdminPanel} from "./admin/panel";
import {default as CompanyPanel} from "./company/panel";
import LOGO from '../images/logo_mkierowca.png'

function Home (props) {
    const { user, isAuthenticated, isVerifying } = props;
    let Panel;

    if(user.Role === 'ROLE_ADMIN'){
        Panel = AdminPanel;
    }else {
        Panel = CompanyPanel;
    }

    return(
        <BrowserRouter>
            <Switch>
                <ProtectedRoute
                    path="/panel"
                    component={Panel}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <Route path="/login" component={Login} />

                <Route render={() => <Redirect to="/login" />} />
            </Switch>
            <Mobile>
                <div>
                    <img alt="Logo Marcos Bis" src={LOGO}/>
                    <p>Proszę otworzyć na urządzeniu o wyższej rozdzielczości.</p>
                </div>
            </Mobile>
        </BrowserRouter>
    )
};

Home.propTypes = {
};

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
        user: state.auth.user
    };
}
export default connect(mapStateToProps)(Home);

const Mobile = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  >div{
    width: 80%;
    max-width: 700px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    p{
      font-size: 20px;
      text-align: center;
      color: ${({theme}) => theme.primaryColor};
      font-weight: 600;
    }
  }
  
  @media (max-width: 1000px){
    display: flex;
  }
`;
