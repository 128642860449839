import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: white;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({theme}) => theme.fourthColor};
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
`;

export const AddTypeBtn = styled.div`
   width: 100%;
   font-size: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 700;
   border-radius: 3px;
   color: ${({theme}) => theme.primaryColor};
   cursor: pointer;
   margin-top: 10px;
   svg{
    font-size: 13px;
    margin-right: 5px;
   }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  >svg{
    margin-left: 0px;
    font-size: ${({theme}) => theme.font.XS};
    color: ${({theme}) => theme.secondColor};
    opacity: 0.5;
    transition: all 0.3s;
    cursor: pointer;
    margin-right: 15px;

    &:hover{
      opacity: 1;
    }
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Block = styled.div`
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`

`;