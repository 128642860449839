import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL } from "../theme/constans";
import { getCookie } from "../theme/cookies";

export function Row({data}) {
  return (
    <tr>
      {
        data.map(column =>
          <td>{column}</td>
        )
      }
    </tr>
  )
};

function Table({columns, rows, url, q, refresh, setLoading}) {
  const timeoutId = useRef()
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [data, setData] = useState([]);

  const fetchItems = useCallback(() => {
    setLoading(true);
    var endpointQuery; 
    if(q){
       endpointQuery = `?q=${q}`;
    }
    else{
      endpointQuery = `?page=${activePage}&q=${q}`
    }
    axios.get(`${API_URL}${url}${endpointQuery}`, {
      headers: {
        'Authorization': getCookie('token')
      }
    })
      .then(res => {
        setLoading(false);
        setData(res.data.content);
        setTotalPages(res.data.totalPages)
      });
  }, [activePage, refresh, q]);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      fetchItems()
    }, 1000)
  }, [activePage, refresh, q, fetchItems])

  var pagination = [];
  for (var i = 0; i < totalPages; i++) {
    pagination.push(i);
  }


  return (
    <TableWrapper>
      <ContentTable cellspacing="0" cellpadding="0">
        <tr>
          {
            columns.map(column =>
              <th>{column}</th>
            )
          }
        </tr>
        {
          data.map(rows)
        }
      </ContentTable>
      {
        totalPages > 1 &&
        <PaginationWrapper>
          <PaginationNumber
            arrow
            disabled={activePage === 0}
            onClick={() => activePage !== 0 ? setActivePage(activePage - 1) : {}}
          ><FontAwesomeIcon icon={faChevronLeft}/></PaginationNumber>

          {
            pagination.map((el, index) =>
              <PaginationNumber
                active={activePage === index}
                onClick={() => setActivePage(index)}
              >{index + 1}</PaginationNumber>
            )
          }

          <PaginationNumber
            arrow
            disabled={activePage === totalPages - 1}
            onClick={() => activePage !== totalPages - 1 ? setActivePage(activePage + 1) : {}}
          ><FontAwesomeIcon icon={faChevronRight}/></PaginationNumber>
        </PaginationWrapper>
      }

    </TableWrapper>
  )
};

Table.propTypes = {};

export default Table;

const PaginationNumber = styled.button`
  color: ${({theme}) => theme.primaryColor};
  font-size: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  border-radius: 3px;
  ${({active}) => active && css`
    background: ${({theme}) => theme.primaryColor};
    color: white;
  `}

  ${({disabled}) => disabled && css`
    opacity: 0.2;
  `}

  ${({arrow}) => arrow && css`
    font-size: 13px;
  `}
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const TableWrapper = styled.div`
  //flex: 1 1;
`;

const ContentTable = styled.table`
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;

  tr {
    border-bottom: 1px solid ${({theme}) => theme.fourthColor};

    &:first-of-type, &:last-of-type {
      border-bottom: 0px;
    }
  }

  th, td {
    border: none;
    padding: 0px;
  }

  th {
    background: ${({theme}) => theme.fourthColor};
    font-size: ${({theme}) => theme.font.XS};
    font-weight: ${({theme}) => theme.font.Regular};
    color: ${({theme}) => theme.secondColor};
    padding: 6px 8px;
    text-align: left;

    &:first-of-type {
      border-radius: 3px 0 0 3px;
    }

    &:last-of-type {
      border-radius: 3px 0 0 3px;
    }

    &.textAlign-center {
      text-align: center;
    }
  }

  td {
    padding: 8px 8px;
    font-size: ${({theme}) => theme.font.XS};
    color: ${({theme}) => theme.thirdColor};
    font-weight: ${({theme}) => theme.font.Light};

    svg {
      margin: 0 auto;
      display: block;
    }

    &.name {
      color: ${({theme}) => theme.secondColor};
      min-width: 200px;
    }

    &.icons {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;

      svg {
        cursor: pointer;
        margin: 0;

        &:first-of-type {
          margin-right: 10px;
        }
      }

    }

    span {
      text-transform: uppercase;
      font-size: ${({theme}) => theme.font.XS};
      border-radius: 3px;
      padding: 3px 15px;
      font-weight: ${({theme}) => theme.font.Bold};
    }

    &.web {
      span {
        background: ${({theme}) => theme.greenBackground};
        color: ${({theme}) => theme.green};
      }
    }

    &.comperia {
      span {
        background: ${({theme}) => theme.blueBackground};
        color: ${({theme}) => theme.blue};
      }
    }

    &.wlasny {
      span {
        background: ${({theme}) => theme.yellowBackground};
        color: ${({theme}) => theme.yellow};
      }
    }

    &.patron {
      span {
        height: 35px;
        width: 35px;
        margin: 0 auto;
        display: block;
        background: ${({theme}) => theme.fourthColor};
        font-weight: ${({theme}) => theme.font.Bold};
        font-size: ${({theme}) => theme.font.XS};
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
