import { Select as MUSelect } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import styled from 'styled-components';


function Select ({value, options, onChange, renderValue}) {
    return(
        <Wrapper>
            <StyledFormControl variant="filled">
                <MUSelect
                    id="demo-simple-select-filled"
                    displayEmpty
                    value={value}
                    onChange={onChange}
                    renderValue={renderValue}
                >
                    {
                        options.map(option => <StyledMenuItem value={option.key}>{option.value}</StyledMenuItem>)
                    }
                </MUSelect>
            </StyledFormControl>
        </Wrapper>
    )
};

Select.propTypes = {
};

export default Select;

const StyledMenuItem = styled(MenuItem)`
 
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: ${({margin}) => margin};
  margin-bottom: 10px;
  >div{
    margin: 0px;
    width: 100%;
  }
  .MuiFilledInput-underline:before,.MuiFilledInput-underline:after{
    display: none;
  }
  .MuiFilledInput-input{
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .MuiFilledInput-root{
    border-radius: 5px !important;
    overflow: hidden;
    background: rgba(0,0,0,0.07) !important;
  }
`;

const Wrapper = styled.div`
  height: max-content;
    width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3px;
   overflow: hidden;
`;
