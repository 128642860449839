import React from 'react';
import styled from 'styled-components'
import LOGO from '../../../images/logo_mkierowca.png'
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList, faCogs, faSignOutAlt, faTruck, faUsers} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../../redux/actions";

function Navigation (props) {
    const {dispatch,setShowCongiuration,setShowPlateNumbers} = props;
    return(
        <Wrapper>
            <div>
                <Logo><img alt="Logo Marcos Bis" src={LOGO}/></Logo>
                <NavLinksWrapper>
                    <StyledNavLink exact to="/panel/drivers">
                        <FontAwesomeIcon icon={faUsers}/> Wszyscy kierowcy
                    </StyledNavLink>
                    <StyledNavLink exact to="/panel/vehicles">
                        <FontAwesomeIcon icon={faTruck}/> Wszystkie pojazdy
                    </StyledNavLink>
                </NavLinksWrapper>
            </div>
            <div>
                <ConfigurationWrapper>
                    <NavLinksWrapper>
                        <StyledButton onClick={() => setShowCongiuration(true)}>
                            <FontAwesomeIcon icon={faCogs}/> Konfiguracja
                        </StyledButton>
                    </NavLinksWrapper>
                    {/*<NavLinksWrapper>*/}
                    {/*    <StyledButton onClick={() => setShowPlateNumbers(true)}>*/}
                    {/*        <FontAwesomeIcon icon={faClipboardList}/> Nr. rejestracyjne*/}
                    {/*    </StyledButton>*/}
                    {/*</NavLinksWrapper>*/}
                </ConfigurationWrapper>

                <StyledButton onClick={() => dispatch(logoutUser())}>
                    <FontAwesomeIcon icon={faSignOutAlt}/> Wyloguj się
                </StyledButton>
            </div>
        </Wrapper>
    )
};

Navigation.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated
    };
}
export default connect(mapStateToProps)(Navigation);


const ConfigurationWrapper = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.fourthColor};
  border-top: 1px solid ${({theme}) => theme.fourthColor};
  margin-bottom: 20px;
  padding-top: 10px;
`;

const Label = styled.label`
  width: 100%;
  background: rgba(0,0,0,0);
  padding: 3px 20px;
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.XS};
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  font-weight: ${({theme}) => theme.font.Bold};
`;

const StyledButton = styled.button`
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 8px;
  padding-left: 20px;
  border-radius: 5px;
  // background: ${({theme}) => theme.primaryBackground};
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.M};
  font-weight: ${({theme}) => theme.font.Regular};
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  
  svg{
    min-width: 25px;
    margin-right: 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 8px;
  padding-left: 20px;
  border-radius: 5px;
  // background: ${({theme}) => theme.primaryBackground};
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.M};
  font-weight: ${({theme}) => theme.font.Regular};
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s;
  cursor: pointer;
  
  svg{
    min-width: 25px;
    margin-right: 10px;
  }
  a{
    font-size: 13px;
    background: ${({theme}) => theme.primaryColor};
    color: white;
    display: block;
    height: 22px;
    width: 25px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    
    svg{
    width: 13px;
    height: 13px;
    margin: 0px;
    }
  }
  &:hover{
     color:${({theme}) => theme.secondColor};
  }
  
  &.active{
    background: ${({theme}) => theme.primaryBackground};
    color:${({theme}) => theme.primaryColor};
  }
  
`;

const NavLinksWrapper = styled.div`

`;

const Logo = styled.div`
  margin-top: 40px;
  padding: 0 20px;
  margin-bottom: 40px;
  img{
    max-height: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Wrapper = styled.div`
  background: white;
  height: 100%;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 20px;
`;
