import { useState } from "react";
import axios from "axios";
import {API_URL} from "../../../../../theme/constans";
import { getCookie } from "../../../../../theme/cookies";
import {connect} from "react-redux";
import Spinner from "../../../../../components/spinner";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import {AlertError, Message} from "../../../../../theme/styledComponents";
import {faExclamationTriangle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {P} from "../../../../../components/p";
import { Snackbar } from "@material-ui/core";

function ChangeCompanyPassword (props) {
    const {company, setShow, refresh} = props;
    const [loading, setLoading] = useState();
    const [error, setError] = useState(null);
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const onSubmit = () => {
        if (pass !== confirmPass) {
            setShowAlert(true);
            return;
        }
        setLoading(true);
        axios.patch(`${API_URL}/admin/companies/${company.Id}/changePassword?password=${pass}`, null, {
            headers: {
                'Authorization': getCookie('token')
            } 
        })
            .then(() => {
                refresh();
                setLoading(false);
                setShow(false);
            })
            .catch(err => {
                console.log(err);
                setError(true);
                setLoading(false);
            })
    };

    const handleOnAlertClose = (e) => {
        setShowAlert(false);
    }

    return(
        <>
            {
                error &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się zmienić hasła
                </Message>
            }
            <P>Zmiana hasła dla administratora <span>{company?.Username}</span> konta firmowego <span>{company?.Name}</span></P>
            <Input password label="Nowe hasło*" onChange={(e) => setPass(e.target.value)}/>
            <Input password label="Powtórz hasło*" onChange={(e) => setConfirmPass(e.target.value)}/>
            <Snackbar open={showAlert} autoHideDuration={50000} onClose={handleOnAlertClose}>
                <AlertError>
                    <FontAwesomeIcon icon={faTimesCircle} color="red" style={{marginRight: '10px'}}></FontAwesomeIcon>
                    Podane hasła się różnią
                </AlertError>
            </Snackbar>
            <Button big style={{marginTop:'10px'}} onClick={onSubmit} disabled={pass.length < 3 || confirmPass.length < 3}>
                {
                    loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Zmień hasło'
                }
            </Button>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}

export default connect(mapStateToProps)(ChangeCompanyPassword);