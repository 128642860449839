import React, {useEffect, useState} from 'react';
import {Message} from "../../../../../theme/styledComponents";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import axios from "axios";
import {API_URL} from "../../../../../theme/constans";
import {connect} from "react-redux";
import {getCookie} from "../../../../../theme/cookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../../components/spinner";
import Checkbox from "../../../../../components/checkbox";
import { isValidStringForParam } from '../../../../../utils/isValidStringForParam';

function AddVehicle (props) {
    const {refresh,setShow,user} = props;

    const [valid,setValid] = useState(true);
    const [loading,setLoading] = useState();
    const [error,setError] = useState(null);

    const [state,setState] = useState({
        Username:'',
        Active:false,
        Password:'',
        IsExternal:false,
    });

    const onSubmit = () => {
        if(isValidStringForParam(state.Username) === false){
            setError(true);
            return;
        }
        setLoading(true);
        axios.post(`${API_URL}/companies/${user.CompanyId}/vehicles`,{
            LicensePlate: state.Username,
            Password: state.Password,
            IsExternal: state.IsExternal,
        },{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setLoading(false);
                refresh();
                setShow(false);
            })
            .catch(err => {
                setError(err.response.data.message);
                setLoading(false);
            })
    };

    const onSubmitDraft = () => {
        setLoading(true);
        axios.post(`${API_URL}/companies/${user.CompanyId}/vehicles/draft?plateNumber=${state.Username}`,null,{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                setLoading(false);
                refresh();
                setShow(false);
            })
            .catch(err => {
                setError(err.response.data);
                setLoading(false);
            })
    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    };

    useEffect(() => {
        let element
        if(state.Active){
            element = state.Password === '' || state.Username === ''
        }else {
            element = state.Username === ''
        }

        setValid(element);
    },[state]);

    return(
        <>
            {
                error !== null &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <div>
                        Nie udało się dodać użytkownika. Spróbuj podać inną nazwę użytkownika.
                    </div>
                </Message>

            }
            <Input label="Numer rejestracyjny*" name="Username" value={state.Username} onChange={onChange}/>
            <Checkbox value={state.Active} onChange={(e) => setState({...state,Active: e.target.checked})} label="Czy chcesz aktywować aplikację?"/>
            {
                state.Active &&
                <Input password label="Hasło*"  name="Password" value={state.Password} onChange={onChange}/>
            }
            {
                state.Active &&
                <Checkbox value={state.IsExternal} onChange={(e) => setState({...state,IsExternal: e.target.checked})} label="Pojazd spedycyjny"/>
            }
            <Button big style={{marginTop:'10px'}} onClick={state.Active ? onSubmit : onSubmitDraft} disabled={valid}>
                {
                    loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Dodaj pojazd'
                }
            </Button>
        </>
    )
}

AddVehicle.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(AddVehicle);
